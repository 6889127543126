
.form {
    background-color: rgba(255, 255, 255, 0.747);
}

/* Contact Style */
.label {
    color: #000;
    font-size: 20px;
    text-align: left;
}
.input {
    border-radius: 0px;
    padding-left: 15px;
    font-size: 13px;
    color: #aaa;
    background-color: #f4f4f4;
    border: 1px solid #eee;
    outline: none;
    box-shadow: none;
    line-height: 40px;
    height: 40px;
    width: 100%;
    margin-bottom: 25px;
}
  
.textarea {
    border-radius: 0px;
    padding-left: 15px;
    padding-top: 10px;
    font-size: 13px;
    color: #aaa;
    background-color: #f4f4f4;
    border: 1px solid #eee;
    outline: none;
    box-shadow: none;
    height: 200px;
    max-height: 250px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;
}
  
 .button {
    font-size: 13px;
    border-radius: 0px;
    font-weight: 600;
    color: #fff;
    text-transform: capitalize;
    background-color: #175bc2;
    display: inline-block;
    height: 44px;
    line-height: 24px;
    outline: none;
    box-shadow: none;
    width: 100%;
    letter-spacing: 0.25px;
    text-decoration: none;
    transition: all 0.3s;
  }
  
  .button:hover {
    color: #175bc2;
    background-color: #f4f4f4;
  }
  