* {
  box-sizing: border-box;
}

body {
  margin: 0;  
  width: 100wh;
  height: 90vh;
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;

}

@-webkit-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
  background-position: 0% 50%
  }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
}