.section {    
    margin-bottom: 6px;
    background-color: transparent;
    border: 4px solid white;    
}

.titleText {
    color: white;
}

.section:hover{
    animation: pulse 1s .5s;
    animation-timing-function: linear;
}

@keyframes pulse {
    0% {transform: scale(1);}
    50% {transform: scale(1.1);}
    100% {transform: scale(1);}
}