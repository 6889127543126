.header {
    width: 100%;
    display: flex;
    height: 5rem;
    background-color: darkcyan;
    padding: 0 2%;    
    align-items: center;
    justify-content: space-between;
}

.image {    
    width: 140px;
}

.image img{
    width: 100%
}

.header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
    text-align: right;
  }

  .home {
      height: 30px;
      width: 30px;
  }