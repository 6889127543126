.aboutImage {
    width: 200px;
    height: 200px;
}

.aboutImage img {
    width: 100%;
    height: 100%;
}

.aboutMe {
    background-color: rgba(255, 255, 255, 0.596);
}